import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import PageLink from "../../Page/Link"
import { context } from "../../../store"
import { l } from "../../../lib/locale"
import { pageURL } from "../../../lib/page"
import { first, last } from "lodash"
import { formatAddressOption } from '../../../lib/address'

const { pages } = require("../../../config/pages.json")
const currentstep = pages.find(i => i.id === "checkout-step-guest")
const nextstep = pages.find(i => i.id === "checkout-step-shipping")

const CheckoutBlockContact = ({ lang, active, index }) => {
  const { layoutProvider } = useContext(context)
  const { store, sessionProvider, cartProvider } = useContext(context)

  const isGuest = store && store.session && store.session.customer
    ? store.session.customer.is_guest
      ? true
      : false
    : true

  const isNewGuest = (store && store.session && store.session.customer) 
    ? false
    : true

  const address_invoice = store.cart && store.cart.cart && store.session && store.session.addresses
    ? store.session.addresses.find(i => i.id_address == store.cart.cart.id_address_invoice)
    : null

  const address_delivery = store.cart && store.cart.cart && store.session && store.session.addresses && store.cart.cart.id_address_delivery != store.cart.cart.id_address_invoice
    ? store.session.addresses.find(i => i.id_address == store.cart.cart.id_address_delivery)
    : null

  // console.log('CheckoutBlockContact cart ', store.cart.cart)
  // console.log('CheckoutBlockContact addresses ', store.session.addresses)
  // console.log('CheckoutBlockContact isGuest ', isGuest)
  // console.log('CheckoutBlockContact isNewGuest ', isNewGuest)
  // console.log('CheckoutBlockContact address_delivery ', address_delivery)
  // console.log('CheckoutBlockContact address_invoice ', address_invoice)

  const { disabled, customer } =
    store && store.session ? store.session : { disabled: false, customer: null }

  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [showLoginButton, setShowLoginButton] = useState(false)
  const [alternateDeliveryAddress, setAlternativeDeliveryAddress] = useState(store.cart && store.cart.cart && store.cart.cart.id_address_delivery != store.cart.cart.id_address_invoice ? 0 : 1)

  // for existing customers 
  const [gender, setGender] = useState(customer ? customer.id_gender : null)
  const [firstname, setFirstname] = useState(customer ? customer.firstname : "")
  const [lastname, setLastname] = useState(customer ? customer.lastname : "")
  const [addressInvoice, setAddressInvoice] = useState(store.cart.cart.id_address_invoice)
  
  // for guests
  const [address1, setAddress1] = useState(address_invoice ? address_invoice.address1 : "")
  const [address2, setAddress2] = useState(address_invoice ? address_invoice.address2 : "")
  const [city, setCity] = useState(address_invoice ? address_invoice.city : "")
  const [postcode, setPostcode] = useState(address_invoice ? address_invoice.postcode : "")
  const [id_country, setCountry] = useState(address_invoice ? address_invoice.id_country : "")
  const [company, setCompany] = useState(address_invoice ? address_invoice.company : "")
  const [vat, setVAT] = useState(address_invoice ? address_invoice.vat_number : "")
  const [mobile, setMobile] = useState(address_invoice ? address_invoice.mobile : "")
  const [deliveryFirstname, setDeliveryFirstname] = useState(address_delivery ? address_delivery.firstname : "")
  const [deliveryLastname, setDeliveryLastname] = useState(address_delivery ? address_delivery.lastname : "")
  const [deliveryCompany, setDeliveryCompany] = useState(address_delivery ? address_delivery.company : "")
  const [deliveryAddress1, setDeliveryAddress1] = useState(address_delivery ? address_delivery.address1 : "")
  const [deliveryAddress2, setDeliveryAddress2] = useState(address_delivery ? address_delivery.address2 : "")
  const [deliveryCity, setDeliveryCity] = useState(address_delivery ? address_delivery.city : "")
  const [deliveryPostcode, setDeliveryPostcode] = useState(address_delivery ? address_delivery.postcode : "")
  const [delivery_id_country, setDeliveryCountry] = useState(address_delivery ? address_delivery.id_country : "")
  const [email, setEmail] = useState(customer ? customer.email : "")
  const [password, setPassword] = useState("")


  //
  const readOnly = false // customer && customer.is_guest ? false : false

  const showAddressForm = (e) => {
    if (e) e.preventDefault()
    layoutProvider.showAddressCreate()
  }

  const saveInfo = async e => {
    if (e) e.preventDefault()

    setError(false)
    setErrorCode("")
    setShowLoginButton(false)

    if (isNewGuest && !error && !email) setError(true)
    if (isNewGuest && !error && !password) setError(true)

    try {
      const account = {
        email,
        password,
        gender,
        firstname,
        lastname,
        newsletter: false,
        lang
      }
      const invoiceAddress = {
        firstname,
        lastname,
        address1,
        address2,
        city,
        postcode,
        id_country,
        company,
        vat_number: vat,
        mobile
      }
      const deliveryAddress = {
        firstname,
        lastname,
        address1: deliveryAddress1,
        address2: deliveryAddress2,
        city: deliveryPostcode,
        postcode: deliveryPostcode,
        id_country: delivery_id_country,
        company: deliveryCompany,
        mobile
      }

      // console.log(customer, account, invoiceAddress, deliveryAddress)

      const result = isNewGuest
        ? await sessionProvider.createGuest(alternateDeliveryAddress == 1 ? invoiceAddress : deliveryAddress, invoiceAddress, account)
        : await sessionProvider.setContact(gender, firstname, lastname, addressInvoice, store.cart.cart)

      if (result) {
        await cartProvider.refresh()
        navigate(nextstep[`path_${lang.suffix}`])
      } else {
        // @todo show error
        setErrorCode("error-checkout-guest")
        setError(true)
      }
    } catch (e) {
      console.log(e)
      setError(true)
      if (e.type === "INVALID_EMAIL") {
        setShowLoginButton(true)
        setErrorCode(`error-checkout-${e.type}`)
      } else {
        setErrorCode("error-checkout-guest")
      }
    }
  }

  return (
    <div className="content">
      <PageLink
        className="form-header d-flex align-items-center clickable"
        to={currentstep[`path_${lang.suffix}`]}
      >
        <h3>
          <span className="form-header-step">{index}</span>
          {l("checkout-info", lang)}
        </h3>
      </PageLink>
      {active && (
        <div className="form-content" id="personal">
          <div className="checkboxes">
            <p>{l("field-gender", lang)}</p>
            <div className="row mb-0">
              <div className="col-2">
                <input
                  className="mr-1"
                  type="checkbox"
                  id="woman"
                  checked={gender === 2}
                  onChange={e => setGender(2)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
                <label htmlFor="woman">{l("field-gender-ms", lang)}</label>
              </div>

              <div className="col-2">
                <input
                  className="mr-1"
                  type="checkbox"
                  id="man"
                  checked={gender === 1}
                  onChange={e => setGender(1)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                />
                <label htmlFor="man">{l("field-gender-mr", lang)}</label>
              </div>
            </div>
          </div>

          <div className="inputs container">
            <div className="row mb-1">
              <div className="field col-12 col-md-6">
                <input
                  className=""
                  value={firstname}
                  onChange={e => setFirstname(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                  placeholder={l("field-firstname", lang)}
                />
              </div>
              <div className="field col-12 col-md-6">
                <input
                  className=""
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly || disabled}
                  placeholder={l("field-lastname", lang)}
                />
              </div>
            </div>

            {isNewGuest ? (
              <>
                <div className="row mb-1">
                  <div className="field col-12 col-md-6">
                    <input
                      className=""
                      value={company}
                      onChange={e => setCompany(e.target.value)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                      placeholder={l("field-company", lang)}
                    />
                  </div>
                  <div className="field col-12 col-md-6">
                    <input
                      className=""
                      value={vat}
                      onChange={e => setVAT(e.target.value)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                      placeholder={l("field-vat", lang)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="field col-12 col-md-8">
                    <input
                      className=""
                      value={address1}
                      onChange={e => setAddress1(e.target.value)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                      placeholder={l("field-address", lang)}
                    />
                  </div>
                  <div className="field col-12 col-md-4">
                    <input
                      className=""
                      value={address2}
                      onChange={e => setAddress2(e.target.value)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                      placeholder={l("field-address2", lang)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="field col-12 col-md-4">
                    <input
                      className=""
                      value={postcode}
                      onChange={e => setPostcode(e.target.value)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                      placeholder={l("field-postcode", lang)}
                    />
                  </div>
                  <div className="field col-12 col-md-8">
                    <input
                      className=""
                      value={city}
                      onChange={e => setCity(e.target.value)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                      placeholder={l("field-city", lang)}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="input-group">
                    <select
                      className="form-control"
                      value={id_country}
                      onChange={e => setCountry(e.target.value)}
                      onBlur={e => setCountry(e.target.value)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                      placeholder={l("field-country", lang)}
                    >
                      <option value="" disabled>
                        {l("field-country", lang)}
                      </option>
                      {store.cart.cart._embedded.countries.map(country => {
                        return (
                          <option
                            key={country.id_country}
                            value={country.id_country}
                          >
                            {country[`name_${lang.suffix}`]}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                <div className="field">
                  <input
                    className=""
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    readOnly={readOnly}
                    disabled={readOnly || disabled}
                    placeholder={l("field-email", lang)}
                  />
                </div>

                <div className="field">
                  <input
                    className=""
                    value={mobile}
                    onChange={e => setMobile(e.target.value)}
                    readOnly={readOnly}
                    disabled={readOnly || disabled}
                    placeholder={l("field-mobile", lang)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="id_address_invoice" className="mt-2">{l('checkout-invoice-address', lang)}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mr-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className={`fas fa-map-marked`}></i>
                        </span>
                      </div>
                      <select name="id_address_invoice" className="form-control" onBlur={e => setAddressInvoice(e.target.value)} onChange={e => setAddressInvoice(e.target.value)} value={addressInvoice} disabled={disabled}>
                        <option value="">&nbsp;</option>
                        {store.session.addresses ? store.session.addresses.map(a => (
                          <option key={`invoice-address-${a.id_address}`} value={a.id_address}>{formatAddressOption(a)}</option>
                        )) : null}
                      </select>
                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary" onClick={showAddressForm}>
                          <i className="fas fa-plus"></i><span className="d-none d-md-inline-block ml-1"> {l('button-create-address', lang)}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {isNewGuest && (
            <>
              <div className="checkboxes">
                <div className="row mb-0">
                  <div className="col-12">
                    <input
                      className="mr-1"
                      type="checkbox"
                      id="alternativeDeliveryAddress"
                      checked={alternateDeliveryAddress === 1}
                      onClick={e => setAlternativeDeliveryAddress(alternateDeliveryAddress === 1 ? 0 : 1)}
                      readOnly={readOnly}
                      disabled={readOnly || disabled}
                    />
                    <label 
                      onClick={e => setAlternativeDeliveryAddress(alternateDeliveryAddress === 1 ? 0 : 1)}
                      htmlFor="woman">{l("field-alternative-delivery-address", lang)}</label>
                  </div>
                </div>
              </div>

              {alternateDeliveryAddress ? '' : (
                <div className="inputs container">
                  <div className="row mb-1">
                    <div className="col-12">
                      <h5>Alternatief Bezorgadres</h5>
                      <h6><i>Als je jouw bestelling liever ophaalt bij een afhaalpunt, kan je dit aangeben bij de volgende stap.</i></h6>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="field col-12 col-md-6">
                      <input
                        className=""
                        value={deliveryFirstname}
                        onChange={e => setDeliveryFirstname(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-firstname", lang)}
                      />
                    </div>
                    <div className="field col-12 col-md-6">
                      <input
                        className=""
                        value={deliveryLastname}
                        onChange={e => setDeliveryLastname(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-lastname", lang)}
                      />
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="field col-12 col-md-6">
                      <input
                        className=""
                        value={deliveryCompany}
                        onChange={e => setDeliveryCompany(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-company", lang)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="field col-12 col-md-8">
                      <input
                        className=""
                        value={deliveryAddress1}
                        onChange={e => setDeliveryAddress1(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-address", lang)}
                      />
                    </div>
                    <div className="field col-12 col-md-4">
                      <input
                        className=""
                        value={deliveryAddress2}
                        onChange={e => setDeliveryAddress2(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-address2", lang)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="field col-12 col-md-4">
                      <input
                        className=""
                        value={deliveryPostcode}
                        onChange={e => setDeliveryPostcode(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-postcode", lang)}
                      />
                    </div>
                    <div className="field col-12 col-md-8">
                      <input
                        className=""
                        value={deliveryCity}
                        onChange={e => setDeliveryCity(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-city", lang)}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="input-group">
                      <select
                        className="form-control"
                        value={delivery_id_country}
                        onChange={e => setDeliveryCountry(e.target.value)}
                        onBlur={e => setDeliveryCountry(e.target.value)}
                        readOnly={readOnly}
                        disabled={readOnly || disabled}
                        placeholder={l("field-country", lang)}
                      >
                        <option value="" disabled>
                          {l("field-country", lang)}
                        </option>
                        {store.cart.cart._embedded.countries.map(country => {
                          return (
                            <option
                              key={country.id_country}
                              value={country.id_country}
                            >
                              {country[`name_${lang.suffix}`]}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {isNewGuest ? (
            <div className="inputs container">
              <div className="row mb-1">
                <div className="col-12">
                  <h5>Account aanmaken (optioneel)</h5>
                  <h6><i>Met een account kan je op elk moment jouw bestelstatus raadplegen.</i></h6>
                </div>
              </div>
              <div className="row mb-1">
                <div className="field col-12">
                  <input
                    type="password"
                    className=""
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    readOnly={readOnly}
                    disabled={readOnly || disabled}
                    placeholder={l("field-password", lang)}
                  />
                </div>
              </div>
            </div>
          ) : ''}

          <div className="inputs container">
            {error && (
              <div className="alert alert-danger">
                {errorCode
                  ? l(errorCode, lang)
                  : l("error-checkout-guest", lang)}
              </div>
            )}

            <div className="d-flex justify-content-md-end">
              {showLoginButton && (
                <PageLink
                  to={pageURL("checkout", lang)}
                  className="btn btn-link mr-1"
                  disabled={disabled || readOnly}
                >
                  <i className="fas fa-chevron-left"></i>{" "}
                  {l("lostpassword-backtologin", lang)}
                </PageLink>
              )}
              <button
                className="btn-medium-secondary next-btn"
                onClick={saveInfo}
                disabled={disabled || readOnly}
              >
                <i className="fas fa-chevron-right"></i> {l("checkout-shipping", lang)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

CheckoutBlockContact.propTypes = {
  lang: PropTypes.object,
  active: PropTypes.bool,
  index: PropTypes.number,
}

export default CheckoutBlockContact
